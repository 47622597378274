import "./Footer.style.css";
import { Navbar, Container, Nav } from "react-bootstrap";
import BuildData from "../../Assets/BuildData";

const Footer = (props) => {
  return (
    <Navbar className="footer-distributed" expand="lg">
      <Container>
        <Nav className="me-auto link-container">
          <Nav.Link
            id="footer-link"
            onClick={() => {
              props.onChangePage("CompanyInformationPage");
            }}
          >
            Impressum
          </Nav.Link>
          <Nav.Link
            id="footer-link"
            onClick={() => {
              props.onChangePage("PrivacyPage");
            }}
          >
            Datenschutz
          </Nav.Link>
          <p className="copyright">
            Grafiken &copy; Jacqueline Ullmann für Fraunhofer FIT
          </p>
          <p className="copyright">
            <a
              href="https://www.fit.fraunhofer.de/"
              target="_blank"
              rel="noreferrer"
            >
              Programmiert von Fraunhofer FIT
            </a>
          </p>
        </Nav>
        {BuildData.BuildType === "Mittelstand" ? (
          <Nav>
            <div className="footer-right">
              <a
                href="https://www.bmwi.de/Navigation/DE/Home/home.html"
                target="_blank"
                rel="noreferrer"
              >
                <img src="footer-logos.png" alt="bmwi" id="footer-logo" />
              </a>
            </div>
          </Nav>
        ) : (
          <></>
        )}
      </Container>
    </Navbar>
  );
};

export default Footer;
