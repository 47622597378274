const EcoDesignData = {
    Main: {
      text: "Ecodesign ist ein Gestaltungsansatz für Produkte, der Umweltbelastungen über den gesamten Produktlebenszyklus mindern soll. Durch eine nachhaltige Produktgestaltung können Unternehmen in der Planungs- und Designphase einen positiven Einfluss auf den gesamten Lebensweg des Produktes nehmen. \n Als systematischer und umfassender Ansatz stellt Ecodesign verschiedene Prinzipien und Methodiken bereit, an denen sich Unternehmen – vor allem im produzierenden Gewerbe – orientieren können.",
      heading: "Eco Design",
    },
    Langlebigkeit: {
      text: "Bei der Herstellung von jedem Produkt entstehen Umweltlasten. Müssen weniger Produkte hergestellt werden, werden diese entsprechen verringert. Ein Produkt ist dann langlebig, wenn es für einen langen Zeitraum seinen Zweck erfüllt. Grundlage dafür ist ein robustes und zeitloses Design. Angenommen, der Lebenszyklus eines Produkts kann durch nachhaltiges Design verdoppelt werden, benötigen die Kund:innen nur ein Produkt statt zwei. Es werden also die Hälfte der Umweltlasten für die Herstellung eingespart. \n Auch finanziell gesehen lohnen sich langlebige Produkte für Unternehmen, denn nicht nur durch den Verkauf von Neuwaren – wie in der Linearwirtschaft üblich – können Einnahmen generiert werden, sondern auf ganz verschiedene Weise, z. B. durch Vermietung, Reparatur oder Sharing.", 
      heading: "Langlebigkeit",
    },
    Reparierbarkeit: {
      text: "Durch einen modularen Aufbau, die Nutzung von lösbaren Verbindungstechniken und die Verwendung standardisierter Bauteile können Produkte besser repariert werden. Durch die Reparierbarkeit kann die Nutzungsdauer eines Produktes verlängert werden.", 
      heading: "Reparierbarkeit",
    },
    NachhaltigeMaterialien: {
      text: "Bei der Materialwahl müssen die Ressourcen betrachtet werden, die beim Abbau der nötigen Rohstoffe, der Herstellung, dem Transport und der Verarbeitung der Materialien benötigt werden. Als nachhaltig gelten daher vor allem langlebige, trennbare und leichte Materialien. \n Der Verzicht auf Problemstoffe bei der Produktherstellung und im Produkt ist sowohl aus Umweltsicht als auch aus Gesundheitsschutzperspektive relevant. Allerdings kann der Verzicht die Langlebigkeit oder die Energieeffizienz beeinflussen.", 
      heading: "Nachhaltige Materialien",
    },
    Energieeffizienz: {
      text: "Energieeffiziente Produkte erfüllen die gewünschte Funktionalität bei einem niedrigeren Energieverbrauch. Das ist besonders relevant für Produkte, die in der Nutzungsphase einen hohen Energieverbrauch haben, wie zum Beispiel Kühlschränke.", 
      heading: "Energieeffizienz",
    },
    NachwachsendeRohstoffe: {
      text: "Die Nutzung regenerativer Rohstoffe ist unter dem Aspekt der Endlichkeit unserer Ressourcen positiv zu sehen. Nachwachsende Rohstoffe sind zum Beispiel Holz, Baumwollfasern, Flachs und Hanf.", 
      heading: "Nachwachsende Rohstoffe",
    },
    Kreislauffähigkeit: {
      text: "Bei der Kreislauffähigkeit geht es darum, Produkte so zu gestalten, dass die genutzten Rohstoffe zum Produktlebensende in die Herstellung neuer Produkte einfließen können. Schließt man Kreisläufe, können demnach Ressourcen geschont, schädliche Emissionen und Energieverschwendung reduziert und Abfälle vermieden werden.", 
      heading: "Kreislauffähigkeit",
    },
  };

export default EcoDesignData;
  