import Tile from "../GraphicTile/GraphicTile.js";
import Data from "../../Assets/Content/StrategyData.js";
import imageMapResize from "image-map-resizer";
import { useEffect } from "react";

const CycleEconomyMap = (props) => {
  const { setContent, economyType, setFixText } = props.AppStateService;

  useEffect(() => {
    imageMapResize();
  }, [])

  return (
    <map
      name="image-map-full"
    > 
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="482,53,481,92,779,92,779,51"
        content={Data.SharingPlatforms}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="486,101,486,142,784,142,784,101"
        content={Data.ProductAsAService}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="486,151,486,192,784,192,784,151"
        content={Data.ReparaturUndWartung}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="0,421,0,462,298,462,298,421"
        content={Data.WasteAsAResource}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="0,470,0,511,298,511,298,470"
        content={Data.ModularesDesign}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="0,518,0,559,298,559,298,518"
        content={Data.SuffizienzUnterstützen}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="933,427,933,468,1231,468,1231,427"
        content={Data.Wiederaufbereiten}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="934,475,934,516,1232,516,1232,475"
        content={Data.Wiederverkauf}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="934,525,934,566,1232,566,1232,525"
        content={Data.Recycling}
      />
    </map>
  );
};

export default CycleEconomyMap;
