import StrategyCycle from "../../Assets/Images/Ökobilanz_Wertgrafik_cycle.jpg";
import StrategyLinear from "../../Assets/Images/Ökobilanz_Wertgrafik_linear.jpg";
import "./StrategyPage.style.css";

import CycleStrategyMap from "../../Components/CycleStrategyMap/CycleStrategyMap";
import Data from "../../Assets/Content/StrategyData.js";
import { useEffect } from "react";

const StrategyPage = (props) => {
  const { content, setContent, economyType, setEconomyType } =
    props.AppStateService;

  useEffect(() => {
    document.title = "Kreislaufwirtschafts-Guide | Strategy";
    setFixText();
  }, []);

  let setFixText = () => {
    if (economyType === "linear") {
      setContent(Data.LinearMain);
    } else {
      setContent(Data.CycleMain);
    }
  };

  let getMap = (economyType) => {
    if (economyType === "linear") {
      return (
        <div id="strategy-map-container">
          <img
            src={StrategyLinear}
            alt=""
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            onClick={() => {
              let fixText =
                economyType === "linear" ? Data.LinearMain : Data.CycleMain;
              setContent(fixText);
            }}
          />
        </div>
      );
    } else {
      return (
        <div id="strategy-map-container">
          <img
            src={StrategyCycle}
            alt=""
            useMap="#image-map-full"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            onClick={() => {
              let fixText =
                economyType === "linear" ? Data.LinearMain : Data.CycleMain;
              setContent(fixText);
            }}
          />
          <CycleStrategyMap
            AppStateService={{
              setContent,
              economyType,
              setFixText,
            }}
          />
        </div>
      );
    }
  };

  return (
    <div>
      <div className="hover-text">
        Wechseln sie zwischen der Ansicht „Kreislaufwirtschaft“ und
        „Linearwirtschaft“ mithilfe der Buttons links.
        <br />
        Fahren sie mit der Maus über die Elemente der Grafik, um mehr zu
        erfahren.
      </div>
      {getMap(economyType)}

      <div className="strategy-text-container">
        <div className="heading">{content?.heading}</div>
        <p
          dangerouslySetInnerHTML={{
            __html: content?.text.replace(/\n/g, "<br />"),
          }}
        />
      </div>
    </div>
  );
};

export default StrategyPage;
