import Tile from "../GraphicTile/GraphicTile.js";
import Data from "../../Assets/Content/EcoDesignData";
import imageMapResize from "image-map-resizer";
import { useEffect } from "react";

const EcoDesignMap = (props) => {
  const { setContent, economyType } = props.AppStateService;

  useEffect(() => {
    imageMapResize();
  }, [])

  return (
    <map
      name="image-map-full"
    > 
      <Tile AppStateService={{ setContent, economyType }}
        coords="300, 237, 300, 285, 256, 311, 66, 278, 65, 233, 264, 210"
        content={Data.Langlebigkeit}
      />
      <Tile AppStateService={{ setContent, economyType }}
        coords="299, 88, 352, 114, 352, 176, 304, 200, 256, 174, 87, 150, 87, 112"
        content={Data.Reparierbarkeit}
      />
      <Tile AppStateService={{ setContent, economyType }}
        coords="132, 27, 132, 63, 343, 72, 403, 108, 478, 88, 480, 34, 416, 6, 356, 17"
        content={Data.NachhaltigeMaterialien}
      />
      <Tile AppStateService={{ setContent, economyType }}
        coords="500, 80, 501, 132, 561, 158, 791, 119, 790, 72, 551, 51"
        content={Data.Energieeffizienz}
      />
      <Tile AppStateService={{ setContent, economyType }}
        coords="545, 204, 549, 277, 610, 292, 930, 253, 934, 200, 604, 177"
        content={Data.NachwachsendeRohstoffe}
      />
      <Tile AppStateService={{ setContent, economyType }}
        coords="550, 332, 548, 407, 611, 432, 864, 395, 861, 332, 603, 308"
        content={Data.Kreislauffähigkeit}
      />
    </map>
  );
};

export default EcoDesignMap;
