import Tile from "../GraphicTile/GraphicTile.js";
import Data from "../../Assets/Content/ContentData.js";
import imageMapResize from "image-map-resizer";
import { useEffect } from "react";

const LinearEconomyMap = (props) => {
  const { setContent, economyType, setFixText } = props.AppStateService;

  useEffect(() => {
    imageMapResize();
  }, [])

  return (
    <map
      name="image-map-full"
    >
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="170,153,273,56,380,159,385,231,465,309,540,317,568,334,562,370,484,385,434,373,361,317,275,266"
        content={Data.Rohstoffe}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="554,312,605,279,577,209,639,171,663,250,671,261,767,262,790,295,761,313,713,313,636,340,573,362,580,328"
        content={Data.Design}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="786,262,863,284,902,223,978,180,1021,201,1001,268,974,327,961,359,987,408,974,446,941,443,879,359,778,321,807,296"
        content={Data.Produktion}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="786,262,863,284,902,223,978,180,1021,201,1001,268,974,327,961,359,987,408,974,446,941,443,879,359,778,321,807,296"
        content={Data.Produktion}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="949,456,979,459,994,428,1012,460,1086,449,1125,526,1087,589,1050,603,1021,596,1012,629,978,641,960,611"
        content={Data.Vertrieb}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="954,625,965,651,1001,648,987,691,1024,740,1007,802,950,812,906,778,867,802,839,786,846,749,907,701"
        content={Data.Nutzung}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="851,807,761,838,720,838,715,924,640,917,593,883,600,802,586,794,566,763,593,738,706,776,829,761,829,789"
        content={Data.Nutzungsende}
      />
      <Tile AppStateService={{ setContent, economyType, setFixText }}
        coords="570,785,553,753,581,733,507,716,439,721,385,757,359,780,341,807,277,826,256,856,266,909,310,921,348,908,377,895,363,848,368,828,406,819,491,776"
        content={Data.Entsorgung}
      />
    </map>
  );
};

export default LinearEconomyMap;
