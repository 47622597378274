const EcoDesignData = {
    LinearMain: {
      text: "In der Linearwirtschaft wird der Wert eines Produktes während der Herstellung aufgebaut und anschließend wird es verkauft. Um finanziell erfolgreich zu sein, müssen möglichst viele Produkte verkauft werden – Unternehmen haben keinen Vorteil darin, ihren Produkten eine überdurchschnittliche Lebensdauer zu geben. \n Da die meisten Unternehmen nach dem Verkauf ihrer Produkte keine weiteren Strategien haben, um Werte zurückzugewinnen, folgt nach der Nutzungsphase ein Werteverfall und es entsteht viel Abfall.",
      heading: "Status quo",
    },
    CycleMain: {
      text: "Im Rahmen der Kreislaufwirtschaft existieren verschiedene Strategien, um den Wert eines Produktes \n\n • mit möglichst wenig (nicht-erneuerbaren) Rohstoffen aufzubauen \n • möglich lange zu erhalten, sodass weniger neue Produkte hergestellt werden müssen \n • nach der Nutzung zurückzugewinnen ",
      heading: "Strategien",
    },
    SuffizienzUnterstützen: {
      text: "Suffizienz beschreibt die Reduzierung des Konsums von Produkten. Dabei ist das Ziel, weniger Produkte zu besitzen und zu nutzen, was allerdings nicht an eine Reduktion der Lebensqualität geknüpft ist. \n Der Bekleidungshersteller Patagonia setzt die Suffizienzstrategie bewusst in ihren Marketingkampagnen ein. Das Unternehmen wirbt mit dem Slogan „Don´t buy this jacket“ dafür, keine Produkte zu kaufen, die man nicht wirklich benötigt. In der Praxis kann Patagonia die Herstellung und den Verkauf von geringeren Mengen durch einen hohen Preis pro Produkt finanzieren.",
      heading: "Suffizienz unterstützen",
    },
    ModularesDesign: {
      text: "Ist ein Produkt modular aufgebaut, besteht es aus unabhängigen Einzelteilen. Diese können jederzeit voneinander getrennt und so ausgetauscht oder in anderen Produkten wiederverwendet werden. \n Sofern die Teile nicht miteinander verklebt sind, ist das Produkt durch den modularen Aufbau reparierbar und kann jederzeit modernisiert oder verbessert werden.",
      heading: "Modulares Design",
    },
    WasteAsAResource: {
      text: "Nutzt ein Unternehmen entstehende Abfälle als Rohstoff für neue Produkte, können damit die Umweltlasten und Kosten für die Bereitstellung von Rohstoffen reduziert werden. Die verwendeten Abfälle können sowohl während der eigenen Produktion entstehen als auch Produktionsabfälle anderer Unternehmen sein. \n Schließen sich mehrere Firmen zusammen, um entstehende Abfälle bestmöglich zu nutzen, nennt man dies eine industrielle Symbiose.",
      heading: "Waste as a Ressource",
    },
    ReparaturUndWartung: {
      text: "Wird ein Produkt repariert, statt durch ein neues ersetzt zu werden, werden Umweltlasten für die Herstellung neuer Produkte reduziert. Vor allem Unternehmen, die für nachhaltige Produkte stehen, bieten Reparaturservices an. Ein Beispiel hierfür ist vaude, ein Hersteller von Outdoor-Produkten. \n Sehr populär sind auch sogenannte „Repair Cafés“, in denen Privatpersonen diverse Produkte reparieren lassen oder unter der Aufsicht von Expert:innen selbst reparieren können.",
      heading: "Reparatur & Wartung",
    },
    ProductAsAService: {
      text: "Von Produkten, die als Services angeboten werden, spricht man dann, wenn diese für eine bestimmte Dauer von den Kund:innen genutzt werden, der Besitz des Produktes aber beim Serviceanbieter verbleibt. Die Anbieter haben die höchsten Einnahmen, wenn das Produkt langlebig und wartungsarm ist. Durch Product as a Service haben die angebotenen Produkte eine verlängerte Nutzungsphase und es müssen weniger neue Produkte hergestellt werden. \n Ein klassisches Beispiel für diese Strategie ist das Leasing eines Produktes, z. B. einer Waschmaschine. Populär ist auch das Mieten von Produkten für besondere Anlässe, z. B. Hochzeiten.",
      heading: "Product as a Service",
    },
    SharingPlatforms: {
      text: "Sharing-Plattformen sorgen dafür, dass mehrere Personen ein Produkt sequenziell nutzen. Die Plattformen stehen den Nutzer:innen nicht nur in virtueller Form (Websites, Apps) zur Verfügung, sondern können auch tatsächlich zugängliche Räume sein, wie z. B. Läden und Cafés. \n Häufig werden Produkte über Sharing-Plattformen geteilt, die nur selten genutzt werden, wie z. B. spezielles Werkzeug oder aufwändige Dekorationen für Feiern. Das bekannteste Beispiel hierfür ist Carsharing. Das Unternehmen Cambio bietet beispielsweise einen Pool verschiedener Autos an, die von Mitgliedern gegen Bezahlung genutzt werden können.",
      heading: "Sharing-Plattformen",
    },
    Wiederaufbereiten: {
      text: "Die Wiederaufbereitung von Produkten oder Produktkomponenten führt dazu, dass weniger Ressourcen für die Herstellung neuer Produkte verbraucht werden. So können Komponenten eines ausrangierten Produktes in die Produktion neuer Ware einfließen oder als Ersatzteile für defekte Produkte dienen. \n Vor allem bei Elektrogeräten kann das Wiederaufbereiten, zum Beispiel durch dein Upgrade der Hard- und Software, für eine verlängerte Nutzungsphase führen.",
      heading: "Wiederaufbereiten",
    },
    Wiederverkauf: {
      text: "Wiederverkäufer:innen generieren Umsatz, indem sie Gebrauchtprodukte an Privatpersonen oder Unternehmen weiterverkaufen. Dadurch verlängern sie die Nutzungsphase von Produkten und es müssen idealerweise weniger Produkte hergestellt werden. \n Ein gutes Beispiel hierfür ist die Plattform reBuy, die gebrauchte Elektronik und Medien von Privatpersonen ankauft, diese zunächst prüft und schließlich weiterverkauft. Dabei erhält der Käufer eine Garantie für 36 Monate.",
      heading: "Wiederverkauf",
    },
    Recycling: {
      text: "Recyclingunternehmen generieren Umsatz durch die Gewinnung und den Verkauf von Sekundärrohstoffen aus Altgeräten bzw. Abfällen. Ein Beispiel ist das Unternehmen Van Werven, welches verschiedene Restkunststoffe aus Bauschutt- und Umweltabfallströmen sortiert, recycelt und dadurch hochwertige Rohmaterialien erzeugt. \n Innovative Produkte und Geschäftsideen führen auch hier zu besseren Wiederverwertungsraten und neuen Ansätzen. Der SmartCrusher beispielweise trennt ungenutzten Zementstein von Betonschutt und produziert dabei Sand und Kies. Diese Materialien können dann als Rohstoffe verkauft werden.",
      heading: "Recycling",
    }
}; 
 
export default EcoDesignData;
  